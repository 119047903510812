<template>
  <div class="all-wen-list">
    <div class="main">
      <div
        class="question-card"
        v-for="(item, index) in allWenList"
        :key="index"
        @click="toAllWenDetail(item)"
      >
        <div class="dialog-ask">
          <div class="common-avatar">问</div>
          <div class="dialog-ask-content">{{ item.content }}</div>
        </div>
        <div class="dialog-answer">
          <img
            class="avatar-default"
            src="../../assets/img/xwTeacher/xw-logo-t.png"
            alt="小文老师"
          />
          <div class="dialog-ask-content" v-html="item.reply"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "all-wen-list",
  data() {
    return {
      allWenList: [],
    };
  },
  created() {
    this.getAllWen();
  },
  methods: {
    getAllWen() {
      this.axios
        .post("/api/user/advisory/anon/resource/list", {
          keyword: "",
          limit: 100,
          offset: 0,
        })
        .then((res) => {
          console.log(res);
          this.allWenList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toAllWenDetail(item) {
      this.$router.push({
        name: "allWenDetail",
        params: {
          detail: item,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.all-wen-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  box-sizing: border-box;
  .main {
    box-sizing: border-box;
    width: 768px;
    background-color: #fff;
    padding: 20px;
    min-height: 100vh;
    .question-card {
      border-radius: 4px;
      border: 1px solid #e8e9eb;
      background-color: #fff;
      display: block;
      flex: 1 1;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
      }
      .dialog-ask {
        display: flex;
        padding: 24px 20px;
        min-height: 100px;
        box-sizing: border-box;
        .common-avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          text-align: center;
          line-height: 50px;
          color: #00c792;
          background-color: rgba(0, 199, 146, 0.2);
          font-size: 20px;
          font-weight: 500;
        }
      }
      .dialog-answer {
        display: flex;
        padding: 24px 20px;
        background: hsla(0, 0%, 96%, 0.4);
        .avatar-default {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #f1f1f1;
        }
      }
      .dialog-ask-content {
        flex: 1 1;
        font-size: 16px;
        line-height: 24px;
        color: #444c57;
        margin-left: 12px;
        display: -webkit-box;
        word-break: break-all;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        white-space: normal !important;
        font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB,
          Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .all-wen-list {
    .main {
      width: 100%;
      .question-card {
        .dialog-ask-content {
          font-size: 13px;
        }
      }
    }
  }
}
</style>